import React, { useState, useEffect } from 'react';
import { parse } from 'query-string';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { sanitize } from 'dompurify';
import { useLayoutQueries } from '../utils/queries';
import storage from '../utils/storage';
import sendRequest from '../utils/forms/API/sendRequest';

export const GhContainer = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding: 20px;
  max-width: 960px;
  margin: 0 auto;

  .center {
    text-align: center;
  }
  .underline {
    text-decoration: underline;
  }
  p,
  li {
    margin-bottom: 15px;
  }
  br {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  a {
    color: #065398;
  }
  a:hover {
    color: #00b;
  }
  #doNotSellModalOpen {
    cursor: pointer;
  }
`;

const GhTos = () => {
  const [tos, setTos] = useState('');
  const { thankyouGhTos } = useLayoutQueries();
  useEffect(() => {
    const { stage, dev } = parse(window.location.search);
    let envToggle = '';
    if (stage === '1' || dev === '1') {
      envToggle = '-default';
    }

    const tosContainer = document.getElementById('tos-container');

    if (tosContainer) {
      tosContainer.addEventListener('click', e => {
        e.preventDefault();
        const { id } = e.target;
        if (id === 'privacyModalOpen') {
          navigate('/gh-pp.html');
        }
      });
    }

    const url = `https://external${envToggle}.printfinger.tech:7105/api/CheckRules`;
    const getStorage = storage('local', 'get', 'formData');

    if (getStorage) {
      const formData = JSON.parse(getStorage);
      const { request_id, offer_id, affiliate_id, site_name } = formData;

      const requestData = {
        activity: 'Disclosure',
        RequestId: request_id || '',
        OfferId: offer_id || '',
        SiteName: site_name || window.location.hostname,
        AffiliateId: affiliate_id || '',
        AdvertiserName: 'Grant Hood Marketing',
      };

      const successFunction = response => {
        try {
          if (!response || !response.Payload) {
            setTos(thankyouGhTos.childPlainText.content);
          } else {
            const disclosureParse = JSON.parse(response.Payload);

            // Turn response into object
            const disclosureObj = {};
            disclosureParse.forEach(item => {
              const key = Object.keys(item);
              disclosureObj[key] = item[key];
            });

            storage(
              'session',
              'set',
              'disclosure_name',
              disclosureObj.DisclosureName
            );

            storage(
              'session',
              'set',
              'AffiliateAssociation',
              disclosureObj.AffiliateAssociation
            );

            setTos(sanitize(disclosureObj.TermsOfUse));
          }
        } catch (error) {
          console.log(error, 'No Terms found');
          setTos(thankyouGhTos.childPlainText.content);
        }
      };

      sendRequest(requestData, url, successFunction, 'POST');
    } else {
      setTos(thankyouGhTos.childPlainText.content);
    }
  }, [thankyouGhTos.childPlainText.content]);

  return (
    <GhContainer
      id="tos-container"
      dangerouslySetInnerHTML={{
        __html: tos,
      }}
    />
  );
};

export default GhTos;
